$anchor-line-back: #416790;
$anchor-circle-back: #20b3f3;
$anchor-active-back: #e4eaf0;

.dev-wiki-wrapper {
    color: #0d3c5d;

    ul.dev-wiki-anchor.not-empty {
        &:before {
            background-color: $anchor-circle-back;
        }
        &:after {
            opacity: 0.2;
            background-color: $anchor-line-back;
            border: 2px solid $anchor-line-back;
        }

        li.active {
            // background: $anchor-active-back;
            // border: 1px solid #d1dae3;

            &:before {
                background: $anchor-circle-back;
            }
        }
    }

    .dev-wiki {

        a {
            color: $anchor-circle-back;
        }

        ul li:before {
            background-color: #2fbe6b;
        }

    }
}
