@import '../../common/styles/variables';

.dev-checkbox .dev-checkbox-item-wrapper,
.dev-radio .dev-radio-item-wrapper {
    > label {
        &:before {
            border: 1px solid #c1d1e0;
        }

        &:hover:before {
            border: solid 0.5px #c1d1e0;
            box-shadow: 0px -0.3px 2px 2px rgba(103, 196, 241, 0.52);
        }
    }
    &.checked > label:before {
        background: #1db4fb;
        border: 1px solid #1db4fb;
    }
    &.checked > label:after {
        color: white;
    }
}
