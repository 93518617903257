@import '../../../css/functions';
@import "../../../css/skins/variables";
@import '../../../css/variables';

.dev-tabs {
    .nav-tabs li a {
        color: #334353;
    }
    .nav-tabs li.active > a {
       color: $color-primary;
       font-weight: bold;
    }
    .dev-tabs-wrapper.navigation {
       .nav-tabs li.active > a {
           color: $color-primary;
           border-bottom: $gap-sm solid $color-primary;
       } 
    }

    .dev-tabs-wrapper.pill {
        .nav-tabs > li.active > a,
        .nav-tabs > li.active > a:hover,
        .nav-tabs > li.active > a:focus{
            background-color: $color-primary;
        }
    }
}
