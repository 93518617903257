@import '../../common/styles/variables';

.dev-system-switch{
    a{
        color: $blue-0-1;
    }
    
    label{
        color: #c5cfd6;
    }
    
    .fa-search{
        color: #000 !important;
    }
} 
.dev-system-switch-btn .brand{
    color: #fff; 
}
.main-header .systems-menu {
    &:hover{
        background: transparent;
        color: #fff;
    }
    
    .system-name, .link-label{
        
        &.active{
            background-color: $blue;
            color: #fff !important;
        }
        &:hover{
            background-color: $blue;
            color: #fff !important;
        }
    }
    
    .dropdown-systems {
      a {
         /*color: $blue;*/
      }
    }
}
