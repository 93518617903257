// Function

@function c-rgba($r, $g, $b, $a: 1) {
    @return rgba($r, $g, $b, $a);
}

@function contains($value, $list) {
    $exist: false;
    @each $item in $list {
        @if $item == $value {
            $exist: true;
        }
    }
    @return $exist;
}
