@import "../../common/styles/variables";

.dev-wiki-anchor.not-empty {
  li > div.active {
    a {
      color: $blue;
    }
    &::before {
      background-color: $blue-2;
    }
  }
}

