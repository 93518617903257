// Variables

/* ************* */
// Font
$font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial;
$font-family-code: "Helvetica Neue","Microsoft YaHei","微软雅黑",Arial;

$font-size-base: 14px;
$line-height-base: $font-size-base + 8px;
$font-weight-base: 400;

$font-size-xs: 10px;
$line-height-xs: $font-size-xs + 8px;
$font-weight-xs: $font-weight-base;

$font-size-sm: 12px;
$line-height-sm: $font-size-sm + 8px;
$font-weight-sm: $font-weight-base;

$font-size-md: 16px;
$line-height-md: $font-size-md + 8px;
$font-weight-md: 500;

$font-size-large: 20px;
$line-height-large: $font-size-large + 8px;
$font-weight-large: $font-weight-md;

$font-size-sub-title: 24px;
$line-height-sub-title: $font-size-sub-title + 8px;
$font-weight-sub-title: $font-weight-md;

$font-size-title: 30px;
$line-height-title: $font-size-title + 8px;
$font-weight-title: $font-weight-md;

$font-size-md-title: 36px;
$line-height-md-title: $font-size-md-title + 8px;
$font-weight-md-title: $font-weight-md;

$font-size-large-title: 42px;
$line-height-large-title: $font-size-large-title + 8px;
$font-weight-large-title: $font-weight-md;

$font-size-xlarge-title: 48px;
$line-height-xlarge-title: $font-size-xlarge-title + 8px;
$font-weight-xlarge-title: $font-weight-md;

$font-size-xxlarge-title: 56px;
$line-height-xxlarge-title: $font-size-xxlarge-title + 8px;
$font-weight-xxlarge-title: $font-weight-md;


/* ************* */
// Gap
$gap-base: 8px;
$gap-sm: $gap-base / 2;
$gap-md: $gap-base * 2;
$gap-large: $gap-base * 3;
$gap-specific: $gap-base + $gap-sm;


/* ************* */
// Color
$color-primary: c-rgba(51,133,255);
$color-link: c-rgba(53,119,219);
$color-success: c-rgba(9,209,116);
$color-error: c-rgba(255,71,71);
$color-danger: $color-error;
$color-warning: c-rgba(255,147,38);
$color-info: c-rgba(51,185,255);
$color-disable: c-rgba(240,242,245);

$body-bg: $gray-7;

// feature color modes
$color-modes: primary, success, error, danger, warning, info, disable;


/* ************* */
// Border
$border-radius: 4px;

/* ************* */
// form
$form-item-height: 32px;
$form-item-height-without-border: 30px;

/* ************* */
// navbar
$navbar-width-normal: 224px;
$navbar-width-md: 184px;
$navbar-width-sm: 88px;
$navbar-logobar-height: 56px;
$navbar-item-height: $gap-base * 5;  // 40px
$navbar-second-width: 148px;
$navbar-logobar-img-width: 30px;
$navbar-logobar-img-width-sm: 24px;
$navbar-title-size: 20px;
$navbar-title-size-sm: 16px;
$navbar-logobar-icon-width: 16px;
$navbar-item-gap: $gap-sm;  // 4px
$navbar-sm-padding: 12px;

// top header
$header-height: $navbar-logobar-height;
$navbar-collapse-width: 18px;

// dropdown menu
$dropdown-item-padding: 12px;
$dropdown-item-height: 32px;

// dropdown projects
$dropdown-projects-max-height: 448px;
$dropdown-projects-min-height: 133px;
$dropdown-projects-width: 732px;
$dropdown-projects-input-height: 46px;

// scroll gap
$scroll-gap: 2px;

// form
$form-item-padding-left: 12px;

// table
$table-padding: 14px;

/* ******************** */
// bootstrap col layout
$col-modes: xs, sm, md, lg;
$columns: 12;
$grid-columns: 12 !default;

// checkbox radio
$checkbox-width: 16px;
$radio-check-width: 8px;

$fitted-width: 50px;
$fitted-height: 20px;


// text-align modes
$text-modes: left, right, center;

// vertical-align modes
$vertical-modes: top, bottom, middle;



// select
$select-panel-max-height: 554px;


// datetimepicker
$datetimepicker-xxx: 554px;
$datetimepicker-padding: 12px;
$datetimepicker-line-height: 30px;

$datetimepicker-container-height: 218px;
$datetimepicker-container-top: 33px;
$datetimepicker-container-line-height: 30px;

$datetimepicker-options-width: 216px;

$datetimepicker-year-input-left: 12px;
$datetimepicker-input-width: 16px;
$datetimepicker-input-height: 22px;

$datetimepicker-split-width: 7px;

$datetime-error-padding: 12px;


$datetimepicker-option-width: 58px;
$datetimepicker-option-head-padding: 9px;
$datetimepicker-option-head-width: 24px;
$datetimepicker-option-head-height: 32px;

$datetimepicker-fast-select-rows: 5;
$datetimepicker-fast-select-item-height: 36px;
$datetimepicker-fast-select-item-width: 80px;

$datetimepicker-option-d-size: 24px;
$datetimepicker-option-width: 58px;

$datetimepicker-option-scroll-width: 72px;
$datetimepicker-option-scroll-item-height: 20px;


// datetimepicker-range
$datetimepicker-range-line-height: 30px;
$datetimepicker-range-simple-year-padding-left: 22px;

$datetimepicker-range-error-top: 33px;

// footer
$footer-height: 50px;
$footer-size: 14px;
