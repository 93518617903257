@import '../../common/styles/variables';

.dev-filter-single-select{
    .container{
        border-color: #eee;
        box-shadow: 1px 1px 1px #eee;
        background-color: $default;
        
        li{
            a{
                color: $blue-plain; 
                
                em{
                    border-bottom-color: $blue-plain;
                }
            }
            
            &.active{
                a{
                    background-color: $blue-bg !important;
                    color: $blue-content-active !important;
                    
                    em{
                        border-bottom-color: $blue-content-active;
                    }
                }
            }
            
            &.hover{
                a{
                    background-color: #f7f7f7;
                }
            }
        }
    }
}