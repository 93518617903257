@import '../../common/styles/variables';

.dev-pager {
    .page-options .btn-default {
        background-color: $blue-page-button;
        border-color: $blue-page-border;

        &:hover, &:focus, &:active, &.focus, &.active {
            background-color: $blue-page-active
        }
    }
}
