@import '../../common/styles/variables';
@import '../variables';
@import "../../../css/functions";
@import "../../../css/mixins";

.dev-timeline {

    .timeline-parent {
        color: $cyan-timeline-parent-font;
        font-size: $cyan-timeline-parent-size;
        .text {
            margin-bottom: $timeline-parenticon-width / 2;
            vertical-align: middle;
        }

        &:after {
            background-color: $cyan-timeline-timeline;
            width: 1px;
        }

        .timeline-parent-open, .timeline-parent-closed {
            background: $cyan-timeline-parent-background;
        }
    }

    .timeline-item {
        .time {
            // color: $cyan-timeline-parent-font;
            font-weight: $cyan-timeline-time-size;
        }

        .dot {
            background-color: $cyan-timeline-itemtitle-background;
        }

        .title-text {
        }

        .pointer{
            @include creat-pointer(10px, $cyan-timeline-timeline, 8px, #ffffff);
        }

        .content {
            background-color: $cyan-timeline-content-background;
            color: $cyan-timeline-content-font;
        }

    }
}
