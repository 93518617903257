@import '../../common/styles/variables';
@import '../variables';

#dev-changelog {

    .changelog-year {
        color: $cyan-changelog-year-font;
        font-size: $cyan-changelog-year-size;
        .text{
            display: inline-block;
            margin-bottom: $changelog-yearicon-width / 2;
            vertical-align: middle;
            width: 86px;
            text-align: center;
        }

        &:after {
            background-color: $cyan-changelog-timeline;
            width: 1px;
        }

        .changelog-year-open, .changelog-year-closed {
            display: inline-block;
            width: $changelog-yearicon-width;
            height: $changelog-yearicon-width;
            border-radius: $changelog-yearicon-width / 2;
            background: $cyan-changelog-year-background;
            position: relative;
            left: -6px;
        }
        .changelog-year-open:after, .changelog-year-closed:after {
            content: '';
            position: absolute;
            display: block;
            border-width: 9px 7px;
            border-style: solid;
            top: 13px;
            left: 9px;
        }
        .changelog-year-open:after {
            border-color: #fff transparent transparent transparent;
        }
        .changelog-year-closed:after {
            border-color: transparent transparent transparent #fff;
            top: 9px;
            left: 13px;
            border-width: 7px 9px;
        }
    }

    .changelog-item {
        .time {
            color: $cyan-changelog-year-font;
            font-size: $cyan-changelog-time-size;
            font-weight: bold;
            display: inline-block;
            text-align: center;
            width: 91.2px;
        }

        .dot {
            background-color: $cyan-changelog-itemtitle-background;
        }

        .title {
            color: $cyan-changelog-itemtitle-font;
            background-color: $cyan-changelog-itemtitle-background;
            border: 1px solid $cyan-changelog-itemtitle-border;
            font-size: 14px;
            font-weight: normal;
            margin-left: 24px;
            margin-bottom: 10px;
            border-radius: 12px;
            padding: 0px 12px;
        }

        .content {
            background-color: $cyan-changelog-content-background;
            color: $cyan-changelog-content-font;
        }

    }
}
