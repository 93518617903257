// Mixins

// Media
@mixin media-up($min) {
    @media (min-width: $min) {
        @content;
    }
}
@mixin media-down($max) {
    @media (max-width: $max) {
        @content;
    }
}
@mixin media-between($min, $max) {
    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null and $min != null {
        @include media-up($min) {
            @content;
        }
    } @else if $min == null and $max != null {
        @include media-down($max) {
            @content;
        }
    } @else {
        @content;
    }
}

// box-shadow
@mixin box-shadow($h, $v, $blur, $spread, $alpha) {
    -webkit-box-shadow: $h $v $blur $spread rgba(17,35,61,$alpha);
    box-shadow: $h $v $blur $spread rgba(17,35,61,$alpha);
}

// brand-color-x
@mixin brand-color($attr, $alpha) {
    #{$attr}: c-rgba(51,133,255,$alpha);
}

// navbar
@mixin narbar-padding() {
    padding: 0px $gap-md $gap-base $gap-large;
}

// iconfont
@mixin iconfont($left, $right) {
    @if $left != null {
        margin-left: $left;
    }
    @if $right != null {
        margin-right: $right;
    }
    font-size: $font-size-base;
    color: inherit;
}

// fix inline-block margin
@mixin block-margin() {
    margin-right: -4px;
}

// common flex
@mixin flex-default() {
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    align-items: center;
}

// grid 布局，信息展示行数 此处定义为公共类
@mixin grid-info-show-row($index) {
    .grid-info-show-row-#{$index} {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: $index;
        -webkit-box-orient: vertical;
    }
}

@mixin grid-columns($cols) {
    .dash-grid.grid-#{$cols} {
        grid-template-columns: repeat($cols, 1fr);
    }
}

@mixin grid-span($type, $start, $span) {
    .dash-grid .grid-#{$type}-#{$start}-#{$span} {
        grid-#{$type}: $start / span $span;
    }
}

// visible display
@mixin visible-display($size, $display) {
    .visible-#{$size}-#{$display} {
        display: $display !important;
    }
}

// col layout
@mixin make-col($size, $columns: $grid-columns, $attr: width) {
    #{$attr}: percentage($size / $columns);
}
@mixin col-row($mode, $index) {
    .col-#{$mode}-#{$index} {
        position: relative;
        min-height: 1px;
    }
}
@mixin make-col-columns($mode) {
    @for $i from 1 through $columns {

        .col-#{$mode}-#{$i} {
            float: left;
            @include make-col($i);
        }
        .col-#{$mode}-pull-#{$i} {
            @include make-col($i, $attr: right);
        }
        .col-#{$mode}-push-#{$i} {
            @include make-col($i, $attr: left);
        }
        .col-#{$mode}-offset-#{$i} {
            @include make-col($i, $attr: margin-left);
        }
    }
}

// table
@mixin table-text($mode) {
    .table-text-#{$mode} {
        tbody tr td,
        tbody tr th,
        thead tr th,
        thead tr td {
            @if contains($mode, $text-modes) {
                text-align: $mode;
            } @else {
                vertical-align: $mode;
            }
        }
    }
}

@mixin creat-pointer($width,  $bg-color, $width-inner, $bg-color-inner){
    width: $width;
    height: $width;
    border-radius: $width / 2;
    background-color: $bg-color-inner;
    border-width: $width - $width-inner;
    border-style: solid;
    border-color: $bg-color; 
}

@mixin select-prompt-area($gap-base){
    .ui-select-message {
        padding-bottom: $gap-sm;
        hr {
            margin: $gap-base 0;
        }
        p {
            margin-bottom: 0;
        }
        > div {
            padding: 0 $gap-base * 2;
        }
        .limit-height {
            max-height: 60px;
            overflow-y: auto;
            color: $gray-4;
        }
    }
}

@mixin dropdown-content {
    .dropdown-content {
        position: absolute;
        z-index: 1000;
        float: left;
        min-width: 160px;
        margin: $gap-sm 0 0;
        font-size: $font-size-base;
        text-align: left;
        background-color: white;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        @include box-shadow(0,0,$gap-base,0,.16);

        .dropdown-menu {
            position: static;
            display: block;
            max-height: 300px;
            padding: 0;
            float: none;
            border: 0;
            @include box-shadow(0,0,0,0,0);
        }
        @include select-prompt-area($gap-base);
    }
}

@mixin badge {
    display: inline-block;
    padding: 0;
    border-radius: $border-radius * 2;
    text-align: center;
    height: $gap-md;
    width: auto;
    min-width: $gap-md;
    padding: 0 $gap-sm;
    line-height: $gap-md;
    vertical-align: middle;
    font-size: $font-size-xs;
    font-weight: $font-weight-xs;
}

@mixin navbar-small {
    grid-template-columns: $navbar-width-sm auto;

    .main-header {
        left: $navbar-width-sm;
    }

    .navbar {
        text-align: center;
        font-size: $font-size-sm;

        .logobar {
            padding: 0;
            h2 {
                display: none;
            }
        }

        > .sidebar-collapse {
            overflow: unset;
        }

        ul.nav > li {
            position: relative;
            > a {
                padding: unset;
                padding: $gap-specific $gap-sm $gap-base;
                line-height: unset;

                i {
                    display: block;
                    margin-bottom: $gap-sm;
                    line-height: $font-size-base;
                }
                .nav-label {
                    display: inline-block;
                    word-break: keep-all;
                    margin-right: 0px;
                    margin-left: 0px;
                    line-height: $font-size-base;
                }
                .arrow {
                    display: none;
                    float: unset;
                    margin-top: unset;
                }
            }
            &.active > a {
                color: $navbar-active-color;
                background-color: $brand-color-6;
            }

            > a:hover {
                color: $navbar-active-color;
            }
            > .nav-second-level {
                display: none;
                background-color: $navbar-sub-bg;
            }

            &:hover > .nav-second-level {
                // fix 小nav模式一级菜单收起，二级菜单active时的hover样式异常
                height: initial!important;
                display: block;
                visibility: visible;
                position: absolute;
                left: $navbar-width-sm + $gap-sm;
                top: 0;
                width: $navbar-second-width;
                background-color: $navbar-sub-bg;
                border-radius: $border-radius;
                text-align: left;
                z-index: 50;

                // fix hover事件，鼠标移动到间距处，二级导航会隐藏的问题
                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 0px;
                    border-left: $gap-sm solid transparent;
                    top: 0;
                    bottom: 0;
                    left:-4px;

                }

                > li {
                    height: $navbar-item-height;

                    > a {
                        padding: unset;
                        padding-left: $gap-md;
                        padding-right: $gap-md;
                        height: $navbar-item-height;
                        line-height: $navbar-item-height;
                    }
                }
            }
        }
    }
}