$timeline-parenticon-width: 18px;
$timeline-itemtitle-borderradius: 6px;
$cyan-timeline-timeline: #3385ff;
$cyan-timeline-parent-size: 18px;
$cyan-timeline-parent-font: #010101;
$cyan-timeline-time-size: 14px;
$cyan-timeline-itemtitle-size: 14px;
$cyan-timeline-parent-background: #3385ff;
$cyan-timeline-itemtitle-background: #416790;
$cyan-timeline-itemtitle-font: #ffffff;
$cyan-timeline-itemtitle-border: rgba(53, 83, 115, 0.3);
$cyan-timeline-content-background: #f0f4fb;
$cyan-timeline-content-font: #0d3c5d;

$orange-timeline-timeline: #e5ddd0;
$orange-timeline-parent-size: 18px;
$orange-timeline-parent-font: #010101;
$orange-timeline-parent-background: #F39c11;
$orange-timeline-time-size: 14px;
$orange-timeline-itemtitle-size: 16px;
$orange-timeline-itemtitle-background: #e5ddd0;
$orange-timeline-itemtitle-font: #000000;
$orange-timeline-itemtitle-border: rgba(53, 83, 115, 0.3);
$orange-timeline-content-background: #f9f8f7;
$orange-timeline-content-font: #000000;


