.dev-notice .notice-item {
    .datetime {
        .date, .time {
            color: #0d3c5d;
        }
    }
    .top:before {
        background: #416790;
    }
    .content {
        background: #f0f4fb;
        color: #416790;
    }
}
