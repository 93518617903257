@import '../../css/functions';
@import '../../css/mixins';
@import '../../css/skins/variables';
@import '../../css/variables';

@mixin theme (
    $border,
    $thead-bg,
    $tbody-bg,
    $active-bg,
    $active-border,
    $checkbox-bg,
    $delete-bg,
    $delete-border
) {
    dev-grid,big-list,dev-treetable  {
        /* position: relative; */
        .dev-table {
            padding: 0;
            border: 1px solid $gray-6;
            border-radius: $border-radius;

            .table {
                margin: 0;
            }
        }
        .table-bordered tr {
            // border: 1px solid $border;
        }

    }
    dev-grid {
        td.open-detail, th.open-detail {
            width: 40px;
        }
        .table-filter {
            .re {
                position: relative;
            }
            .abs {
                position: absolute;
            }
            .td-value-clear {
                position: relative;
            }
            .input-value-clear {
                right: $gap-specific;
                top: $gap-sm + 1;
            }
            .form-control {
                padding-right: $gap-specific + $gap-sm + $font-size-sm;
            }
            .select-value-clear {
                position: absolute;
                right: $gap-specific + $font-size-sm + $gap-sm;
                top: -$gap-sm + 1px;
            }
        }
    }
    dev-nested-grid {
        thead tr {
            background-color: $nested-thead-bg;
        }
    }
}

.dev-table {
    .table-btn-group {
        position: relative;
    }
    .table-btn-group button {
        margin-right: 4px;
    }
}

.dev-table.loading {
    pointer-events: none;
    opacity: 0.5;
}

.dev-table.repeater-container {
	max-height: 900px;
    overflow: auto;
}

.dev-table .spin {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 35px;
    z-index: 999;
}

.dev-table .table-head{
    th{
        position: relative;
    }

    th.icon-screening-padding{
        padding-right: $gap-md + $gap-base + 14;
    }

    .icon-screening.open-selector{
        position: absolute;
        right: $gap-md;
        color: $gray-2;
        font-size: 14px;
    }
}


.cell-tree {
    position: relative;
}

.cell-tree .badge {
    position: absolute;
    right: 0px;
}

.dev-table .text-edit {
    input {
        display: inline;
        width: 150px;
    }
}

.dev-table .dev-nested-table {
    td input {
        max-width: 100px;
    }
}
.nested-table {
    text-align: left;
    dev-pager {
        padding: $gap-md 0;
        &:first-of-type {
            padding-top: 0;
        }
        &:last-of-type {
            padding-bottom: 0;
        }
    }
    .table {
        margin: 0;
    }
}

//升降序icon颜色
.cell-lift .lift-icon {
    i, .lift-font {
        &.down {
            color: $color-danger;
        }
        &.up {
            color: $color-success;
        }
    }
}

// select message bar
.message-bar {
    height: $form-item-height + $gap-sm;
    border: 1px solid c-rgba(194,218,255);
    border-radius: $border-radius;
    background-color: c-rgba(240, 246, 255);
    display: flex;
    align-items: center;
    padding: 0 $gap-md;
    margin: $gap-md 0;
    font-size: $font-size-sm;
    color: $gray-2;

    strong {
        font-weight: bold;
        padding: 0 $gap-sm;
        color: #000;
    }

    .clear-btn {
        margin-left: $gap-base;
    }
}
//多选checkbox模拟
.multiple-theme-checkbox {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    cursor: pointer;
    border: 1px solid $gray-5;
    vertical-align: middle;
    border-radius: $border-radius / 2;
    position: relative;

    &:before {
        font-family: iconfont;
        color: #fff;
        font-size: 14px;
        text-align: center;
    }

    // 全选
    &.all {
        background-color: $color-primary;
        border-color: $color-primary;
        &:before {
            content: '\e639';
        }
    }
    &.page:before {
        content: '\e639';
        color: $color-primary;
    }
    &.part:before {
        content: '\e6c2';
        color: $color-primary;
    }

    &:hover {
        border-color: $color-primary;
        .thead-checkbox-popover {
            display: block;
        }
    }
    .thead-checkbox-popover {
        display: none;
        top: 100%;
        left: 0;
        width: 120px;
        max-width: initial;
        &:before {
            position: absolute;
            top: -10px;
            display: block;
            content: '';
            width: 30px;
            height: 10px;
        }
        .popover-content {
            padding: 4px 0;
            margin: 0;

            ul > li > a {
                display: block;
                height: $form-item-height;
                line-height: $form-item-height;
                padding: 0 12px;
                font-size: 14px;
                color: $gray-2;

                &:hover {
                    background-color: $brand-color-4;
                }
            }
        }
    }
}