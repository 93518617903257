@import "../../common/styles/variables";

#app-index {
  // header
  //.main-header {
  //  .navbar-right > .nav {
  //    > li > a,
  //    > li > a i {
  //      background: transparent;
  //      color: white;
  //    }
  //
  //    > li > a:hover,
  //    > li > a:focus {
  //      background: white;
  //      color: $orange;
  //    }
  //
  //    > li > a:hover i,
  //    > li > a:focus i {
  //      color: $orange;
  //    }
  //  }
  //}

  // sidebar
  .navbar-static-side {
    li.active {
      border-left: 5px solid $blue-m1;
      background: $blue-4;
    }
  }
}
